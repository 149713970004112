import { navigate } from "gatsby"
import React, { useEffect } from "react"
import PodcastPage from "../podcast"

interface Props {
  location: { state: { formCompleted: boolean } }
}

const PodcastThanks = (props: Props) => {
  useEffect(() => {
    if (!props.location.state?.formCompleted) {
      navigate("/podcast")
    }
  }, [props])

  return <PodcastPage didSubmit />
}

/** export */
export default PodcastThanks
