import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import SubmitPodcastWaitingListSection from "../components/static-sections/SubmitPodcastWaitingListSection"
import PodCastSection from "../components/static-sections/PodcastSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"

/** color theme */
const colorPalette = theme.colorPalettes.purple

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import StarReviewIcon from "../../assets/five-star-rating.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"
import NorhartBlackCircleIcon from "../../assets/norhart-black-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const PodcastPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/podcast/",
          name: "Podcast | Norhart",
          image: `${config.siteMetadata.siteUrl}/podcast/podcast-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Podcast | Norhart"
      description="Listen to Norhart's CEO Mike Kaedings Podcast Becoming a Unicorn on Apple, Google, Spotify, and More."
      keywords="norhart, podcast, podcasts, apple, google, spotify"
      imageTwitter={`${config.siteMetadata.siteUrl}/podcast/norhart-podcast-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/podcast/norhart-podcast-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Podcast"
        subtitle="Becoming A Unicorn"
        imageTitle="Norhart Support"
        image="/podcast/norhart-podcast-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Join The Journey"
        tagLine="Listen To Our Upcoming Podcast on Apple, Spotify, and More"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <SubmitPodcastWaitingListSection
        colorPalette={colorPalette}
        icon={NorhartBlackCircleIcon}
        iconTitle="VIP Access"
        didSubmit={props.didSubmit}
      />

      {/* <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroTop}
        icon={NorhartBlackCircleIcon}
        iconTitle="Podcast"
        title="The Podcast That Will Move The Needle"
        subTitle="As a society we celebrate unicorns.  These fast growing private companies that reach a billion dollar valuation.You may glorify or vilify them.  About the only thing you can't do is ignore them because they change things. - Mike Kaeding, CEO Norhart"
        image="norhart-podcast-move-the-needle.png"
        imageAlt=""
        textColor="#FFFFFF"
      /> */}

      {/* <PodCastSection /> */}
    </Layout>
  )
}

/** export */
/** export */
export default PodcastPage
