import React, { useEffect, useState } from "react"
import { ColorPalette } from "../Theme"
import { Formik, Form, FormikHelpers, useFormikContext } from "formik"
import Input from "../form/Input"
import axios from "axios"
import * as yup from "yup"
import { useCookies } from "react-cookie"
import { globalHistory } from "@reach/router"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { navigate } from "gatsby"

interface Props {
  colorPalette: ColorPalette
  icon: any
  iconTitle: string
  didSubmit: boolean
}

const SubmitPodcastWaitingListSection: React.FC<Props> = (props) => {
  const successRef = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (props?.didSubmit) {
      successRef.current?.scrollIntoView()
    }
  }, [props?.didSubmit])

  if (props?.didSubmit) {
    return (
      <div ref={successRef}>
        <Success {...props} />
      </div>
    )
  }
  return <SurveyForm {...props} />
}

/** export */
export default SubmitPodcastWaitingListSection

interface Values {
  firstName: string
  lastName: string
  emailAddress: string
}

const FormErrorMessage: React.FC = () => {
  const { errors, touched } = useFormikContext<Values>()
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    for (const property in errors) {
      if (touched[property]) {
        setShowError(true)
        return
      }
    }
    setShowError(false)
  }, [errors, touched])

  if (showError) {
    return (
      <div className="alert alert-danger" role="alert">
        Oh no, there are some errors above!
      </div>
    )
  } else {
    return null
  }
}

const SurveyForm: React.FC<Props> = ({ icon, iconTitle, colorPalette }) => {
  const Icon = icon
  const [cookies, setCookie] = useCookies(["podcast-waiting-list"])

  const validationSchema = yup.object().shape({
    firstName: yup.string().optional(),
    lastName: yup.string().optional(),
    emailAddress: yup.string().email().required("Required"),
  })

  const formData = {
    firstName: "",
    lastName: "",
    emailAddress: "",
  }

  const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    const email = values["emailAddress"] ?? null
    const firstName = values["firstName"]
    const lastName = values["lastName"]

    const json = {
      surveyId: "7aa7f32b-57d1-4290-a6c0-f9086d673c6c",
      firstName: firstName,
      lastName: lastName,
      emailAddress: email,
    }

    await axios.post(process.env.GATSBY_SURVEY_BASE_URL + "/submit", json)

    const path = globalHistory.location.pathname

    try {
      const data = addToMailchimp(
        email,
        {
          FNAME: firstName,
          LNAME: lastName,
          PATHNAME: path,
        },
        "https://norhart.us6.list-manage.com/subscribe/post?u=1b3da8a041614f17d24adc35d&amp;id=234090fdae&amp;f_id=00dc3de3f0"
      )

      if (data.result == "success") {
        setCookie("podcast-waiting-list", "true", { path: "/" })
      } else if (/is already subscribed/gi.test(data.msg)) {
        setSubmitting(false)
        setCookie("podcast-waiting-list", "true", { path: "/" })
      }
      navigate("/podcast/thanks", { state: { formCompleted: true } })
    } catch (error) {
      console.error(error)
    }
    setSubmitting(false)
  }

  return (
    <Formik enableReinitialize initialValues={formData} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <section
            className="container-fluid sectionBaseColor pt-1 pb-5"
            style={{
              color: `${colorPalette.sectionText}`,
            }}
          >
            <div className="container pl-3 pr-3 text-leading">
              <div className="container pb-5"></div>

              <div
                className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl mb-4"
                style={{ fontSize: "24px" }}
              >
                <Icon />
              </div>

              <div className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl" style={{ fontSize: "24px" }}>
                VIP Access
              </div>

              <div className="contentHeroSubTitle hidden-sm hidden-md hidden-lg hidden-xl" style={{ fontSize: "20px" }}>
                Sign up today to be added to our Norhart Podcast VIP list! We will be launching our Podcast in 2023, so
                stay tuned!
              </div>

              <hr className="mb-5" />

              <div className="row">
                <div className="col">
                  <Input name="firstName" label="First Name" placeholder="John" />
                </div>
                <div className="col">
                  <Input name="lastName" label="Last Name" placeholder="Appleseed" />
                </div>
              </div>

              <div className="mb-3 mt-3">
                <Input
                  variant="normal"
                  name={"emailAddress"}
                  label={"Email address"}
                  required
                  placeholder={"john.appleseed@example.com"}
                />
              </div>

              <div className="mb-3">
                <div className="mt-3">
                  <i>
                    <strong>Transparency:</strong> We are committed to keeping your information confidential. We do not
                    sell, rent, or lease our data to third parties, and we will not provide your personal information to
                    any third party individual, government agency, or company at any time unless compelled to do so by
                    law.
                    <p />
                    <strong>Opt In:</strong> By providing your phone number, you agree to receive text messages from
                    Norhart regarding updates, offers, and promotions. Reply STOP to opt-out anytime. Message and data
                    rates may apply. For more information, please read our{" "}
                    <a href="https://www.norhart.com/legal/privacy/">https://www.norhart.com/legal/privacy/</a> and{" "}
                    <a href="https://www.norhart.com/legal/terms/">https://www.norhart.com/legal/terms/</a>.
                  </i>
                </div>
              </div>

              <FormErrorMessage />

              <button
                type="submit"
                className="btn btn-md btn-light animate__animated animate__pulse animate__infinite animate__slow mb-5"
                style={{ backgroundColor: "#333333", border: "0px", color: "#ffffff", fontWeight: "bold" }}
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                )}
                {isSubmitting ? " Submitting" : "Submit"}
              </button>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  )
}

const Success: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid sectionBaseColor pt-3 pb-5"
      style={{
        color: `${props.colorPalette.sectionText}`,
      }}
    >
      <div className="container pl-3 pr-3 text-center">
        <div className="container pb-5"></div>
        <h2
          className="contentHeroTitle"
          style={{
            color: "#333333",
          }}
        >
          <br />
          Your On Our VIP List
        </h2>

        <h4 className="contentHeroSubTitle mb-5" style={{ color: "#333333", opacity: "0.8" }}>
          Thx for signing up! We will keep you up-to-date on our upcoming Podcast!
        </h4>
      </div>
    </section>
  )
}
